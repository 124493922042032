<template>
  <div id="app">
    <b-navbar type="light" variant="light">
      <b-navbar-brand
        ><img src="./assets/logo_everymobile.svg"
      /></b-navbar-brand>
    </b-navbar>

    <b-container class="bv-example-row">
      <div id="2">
        <br />

        <ValidationObserver ref="observer" v-slot="passes">
          <b-form novalidate @submit.prevent="passes(onSubmit)">
            <b-card header="基本情報の入力">
              <b-row>
                <b-col>
                  <b-table-simple>
                    <b-tr>
                      <b-th class="text-right">ご契約者名(漢字)</b-th>
                      <b-td>
                        <b-form inline>
                          <b-row>
                            <b-col cols="6" sm="6">
                              <label
                                class="sr-only"
                                for="inline-form-input-name"
                                >姓</label
                              >

                              <ValidationProvider
                                name="姓"
                                rules="required"
                                v-slot="{ errors, valid, validated }"
                              >
                                <b-form-input
                                  :state="validated ? valid : null"
                                  id="inline-form-input-name"
                                  aria-label="Last name"
                                  class="mb-2 mr-sm-2 mb-sm-0"
                                  v-model="last_name"
                                  placeholder="絵振"
                                ></b-form-input>
                                <b-form-text text-variant="danger"
                                  >必須</b-form-text
                                >
                                <b-form-invalid-feedback>{{
                                  errors[0]
                                }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </b-col>
                            <b-col cols="6">
                              <label
                                class="sr-only"
                                for="inline-form-input-name"
                                >名</label
                              >

                              <ValidationProvider
                                name="名"
                                rules="required"
                                v-slot="{ errors, valid, validated }"
                              >
                                <b-form-input
                                  :state="validated ? valid : null"
                                  id="inline-form-input-username"
                                  v-model="first_name"
                                  placeholder="太郎"
                                ></b-form-input>
                                <b-form-text text-variant="danger"
                                  >必須</b-form-text
                                >
                                <b-form-invalid-feedback>{{
                                  errors[0]
                                }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                        </b-form>
                      </b-td>
                    </b-tr>

                    <b-tr>
                      <b-th class="text-right">ご契約者名(カナ)</b-th>
                      <b-td>
                        <b-form inline>
                          <b-row>
                            <b-col cols="6" sm="6">
                              <label
                                class="sr-only"
                                for="inline-form-input-name"
                                >姓(カナ)</label
                              >
                              <ValidationProvider
                                name="姓(カナ)"
                                rules="required"
                                v-slot="{ errors, valid, validated }"
                              >
                                <b-form-input
                                  :state="validated ? valid : null"
                                  id="inline-form-input-name"
                                  v-model="last_name_kana"
                                  class="mb-2 mr-sm-2 mb-sm-0"
                                  placeholder="エブリ"
                                ></b-form-input>
                                <b-form-text text-variant="danger"
                                  >必須</b-form-text
                                >
                                <b-form-invalid-feedback>{{
                                  errors[0]
                                }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </b-col>
                            <b-col cols="6">
                              <label
                                class="sr-only"
                                for="inline-form-input-name"
                                >名(カナ)</label
                              >
                              <ValidationProvider
                                name="名(カナ)"
                                rules="required"
                                v-slot="{ errors, valid, validated }"
                              >
                                <b-form-input
                                  :state="validated ? valid : null"
                                  id="inline-form-input-name"
                                  v-model="first_name_kana"
                                  placeholder="タロウ"
                                ></b-form-input>
                                <b-form-text text-variant="danger"
                                  >必須</b-form-text
                                >
                                <b-form-invalid-feedback>{{
                                  errors[0]
                                }}</b-form-invalid-feedback>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                        </b-form>
                      </b-td>
                    </b-tr>

                    <b-tr>
                      <b-th class="text-right">性別</b-th>
                      <b-td>
                        <b-form inline>
                          <div>
                            <ValidationProvider
                              name="性別"
                              rules="required"
                              v-slot="{ errors, valid, validated }"
                            >
                              <b-form-radio-group
                                button-variant="outline-primary"
                                size="lg"
                                name="radio-btn-outline"
                                :state="validated ? valid : null"
                                buttons
                              >
                                <b-form-radio
                                  v-model="gender"
                                  name="radio-size"
                                  size="lg"
                                  value="0"
                                >
                                  男性</b-form-radio
                                >
                                <b-form-radio
                                  v-model="gender"
                                  name="radio-size"
                                  size="lg"
                                  value="1"
                                >
                                  女性</b-form-radio
                                >
                              </b-form-radio-group>
                              <b-form-text text-variant="danger"
                                >必須</b-form-text
                              >
                              <b-form-invalid-feedback>{{
                                errors[0]
                              }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                        </b-form>
                      </b-td>
                    </b-tr>

                    <b-tr>
                      <b-th class="text-right">生年月日</b-th>
                      <b-td>
                        <b-row>
                          <b-col sm="3" xs="8">
                            <ValidationProvider
                              name="年"
                              rules="required"
                              v-slot="{ errors, valid, validated }"
                            >
                              <b-form-select
                                v-model="year"
                                :options="years"
                                :state="validated ? valid : null"
                              ></b-form-select>
                              <b-form-text text-variant="danger"
                                >必須</b-form-text
                              >
                              <b-form-invalid-feedback>{{
                                errors[0]
                              }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="1" xs="4">年</b-col>
                          <b-col sm="3" xs="8">
                            <ValidationProvider
                              name="月"
                              rules="required"
                              v-slot="{ errors, valid, validated }"
                            >
                              <b-form-select
                                v-model="month"
                                :options="months"
                                :state="validated ? valid : null"
                              ></b-form-select>
                              <b-form-invalid-feedback>{{
                                errors[0]
                              }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="1" xs="4">月</b-col>
                          <b-col sm="3" xs="8">
                            <ValidationProvider
                              name="日"
                              rules="required"
                              v-slot="{ errors, valid, validated }"
                            >
                              <b-form-select
                                v-model="day"
                                :options="days"
                                :state="validated ? valid : null"
                              ></b-form-select>
                              <b-form-invalid-feedback>{{
                                errors[0]
                              }}</b-form-invalid-feedback>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="1" xs="4">日</b-col>
                        </b-row>
                      </b-td>
                    </b-tr>

                    <b-tr>
                      <b-th class="text-right">住所</b-th>
                      <b-td>
                        <label for="">郵便番号:</label>
                        <ValidationProvider
                          name="zipcode"
                          rules="required|postalcode"
                          v-slot="{ errors, valid, validated }"
                        >
                          <b-input-group
                            prepend="〒"
                            class="mb-2 mr-sm-2 mb-sm-0"
                          >
                            <b-form-input
                              type="text"
                              prepend="〒"
                              v-model="zipcode"
                              @blur="autoCompleteAddress(zipcode)"
                              placeholder="000-0000"
                              :state="validated ? valid : null"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                @click="autoCompleteAddress(zipcode)"
                                variant="primary"
                                >住所自動入力</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                          <b-form-text text-variant="danger">必須</b-form-text>
                          <b-form-invalid-feedback>{{
                            errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>
                        <br />

                        <label for="address1">都道府県/市区町村:</label>

                        <b-form-input
                          readonly
                          type="text"
                          id="address1"
                          v-model="address1"
                        ></b-form-input>

                        <br />
                        <label for="address2">丁目番地:</label>
                        <ValidationProvider
                          name="丁目番地ß"
                          rules="required"
                          v-slot="{ errors, valid, validated }"
                        >
                          <b-form-input
                            type="text"
                            placeholder="例) 1-1-1"
                            v-model="address2"
                            :state="validated ? valid : null"
                          ></b-form-input>
                          <b-form-text text-variant="danger">必須</b-form-text>
                          <b-form-invalid-feedback>{{
                            errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>

                        <br />
                        <label for="address3">マンション名/部屋番号:</label>
                        <b-form-input
                          type="text"
                          placeholder="例) 〇〇マンション101号室"
                          v-model="address3"
                        ></b-form-input>
                        <br />
                      </b-td>
                    </b-tr>

                    <b-tr>
                      <b-th class="text-right">メールアドレス</b-th>
                      <b-td>
                        <ValidationProvider
                          name="mailaddress"
                          rules="required|email"
                          v-slot="{ errors, valid, validated }"
                        >
                          <b-form-input
                            type="text"
                            v-model="email_a"
                            :state="validated ? valid : null"
                            placeholder="例) every@everymobile.jp"
                          ></b-form-input>
                          <b-form-text text-variant="danger">必須</b-form-text>
                          <b-form-invalid-feedback>{{
                            errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>

                        <br />
                        <label for="email_a"
                          >確認のため再度入力してください</label
                        >
                        <ValidationProvider
                          name="reMailaddress"
                          rules="required|emailReInput:@mailaddress"
                          v-slot="{ errors, valid, validated }"
                        >
                          <b-form-input
                            type="text"
                            v-model="email_b"
                            placeholder="例) every@everymobile.jp"
                            :state="validated ? valid : null"
                          ></b-form-input>
                          <b-form-text text-variant="danger">必須</b-form-text>
                          <b-form-invalid-feedback>{{
                            errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-td>
                    </b-tr>

                    <!--
                    <b-tr>
                      <b-th class="text-right">エブリIDの登録</b-th>
                      <b-td>
                        <p>
                          マイページのご利用に必要なIDです。お好きなIDを入力の上、登録可能かどうかご確認ください。
                        </p>

                        <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                          <b-form-input
                            type="text"
                            v-model="everyid"
                          ></b-form-input>
                          <b-input-group-append>
                            <b-button
                              @click="autoCompleteAddress(zipcode)"
                              variant="primary"
                              >登録可能か確認</b-button
                            >
                          </b-input-group-append>
                        </b-input-group>
                      </b-td>
                    </b-tr>
                    -->

                    <b-tr>
                      <b-th class="text-right">パスワード(6文字以上)</b-th>
                      <b-td>
                        <p>マイページのご利用に必要なパスワードです</p>

                        <ValidationProvider
                          name="password_a"
                          rules="required|min:6"
                          v-slot="{ errors, valid, validated }"
                        >
                          <b-input-group>
                            <b-form-input
                              id="password"
                              :type="inputType"
                              v-model="passwd_a"
                              aria-describedby="password-help password-feedback"
                              placeholder="パスワードを入力"
                              :state="validated ? valid : null"
                              trim
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                variant="outline-success"
                                @click="onClick"
                              >
                                <span><b-icon :icon="iconType"></b-icon></span>
                                <!--
                      <span v-if="isChecked">
                        <b-icon if="isChecked" icon="eye-slash"></b-icon>
                      </span>
                      <span v-else>
                        <b-icon icon="eye"></b-icon>
                      </span>
                      -->
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <b-form-text text-variant="danger">必須</b-form-text>
                          <b-form-invalid-feedback>{{
                            errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>

                        <!--
                        <b-form-input
                          type="password"
                          v-model="passwd_a"
                        ></b-form-input>
                        -->

                        <br />
                        <label for="passwd_b"
                          >確認のため再度入力してください</label
                        >

                        <ValidationProvider
                          name="confirm"
                          rules="required|passwordReInput:@password_a"
                          v-slot="{ errors, valid, validated }"
                        >
                          <b-input-group>
                            <b-form-input
                              id="passwd_b"
                              :type="inputType"
                              v-model="passwd_b"
                              aria-describedby="password-help password-feedback"
                              placeholder="パスワードを入力"
                              :state="validated ? valid : null"
                              trim
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                variant="outline-success"
                                @click="onClick"
                              >
                                <span><b-icon :icon="iconType"></b-icon></span>
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <b-form-text text-variant="danger">必須</b-form-text>
                          <b-form-invalid-feedback>{{
                            errors[0]
                          }}</b-form-invalid-feedback>
                        </ValidationProvider>

                        <!--
                        <b-form-input
                          type="password"
                          v-model="passwd_b"
                        ></b-form-input>
                      -->
                      </b-td>
                    </b-tr>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card>
            <br />

            <div
              align="center"
              v-if="passes.invalid || !passes.validated || !address1"
            >
              <b-button
                variant="outline-secondary"
                pill
                size="lg"
                :disabled="passes.invalid || !passes.validated || !address1"
                >フォームを全て入力してください</b-button
              >
            </div>
            <div align="center" v-else>
              <b-button pill size="lg" variant="outline-success" @click="signUp"
                >登録する</b-button
              >
            </div>
          </b-form>
        </ValidationObserver>
      </div>
    </b-container>
    <br />
    <footer class="border-top">
      <!--
      <b-nav align="center">
        <b-nav-item active>Active</b-nav-item>
        <b-nav-item>Link</b-nav-item>
        <b-nav-item>Another Link</b-nav-item>
        <b-nav-item disabled>Disabled</b-nav-item>
      </b-nav>
      -->
      <br />
      <p align="center">&copy; everymobile inc.</p>
    </footer>
  </div>
</template>

<script>
import firebase from "firebase/compat";
//import HelloWorld from "./components/HelloWorld.vue";
import { extend, validate } from "vee-validate";
import { required, numeric, regex, confirmed } from "vee-validate/dist/rules";
extend("required", required);
extend("numeric", numeric);
extend("regex", regex);
extend("confirmed", confirmed);
extend("postalcode", (value) => {
  return /^[0-9]{3}-?[0-9]{4}$/.test(value) || "{_field_} が異なります";
});

validate("zipcode", "required|postalcode").then((result) => {
  //console.log("aaaaaaAAA")
  if (result.valid) {
    // Do something
    this.aaa();
  }
});

export default {
  name: "App",
  computed: {
    inputType: function () {
      return this.isChecked ? "password" : "text";
    },
    iconType: function () {
      return this.isChecked ? "eye-slash" : "eye";
    },
    years() {
      const years = [];
      for (let year = 1900; year <= new Date().getFullYear(); year++) {
        years.push(year);
      }
      return years;
    },
    months() {
      const months = [...Array(12)].map((ele, i) => i + 1);
      return months;
    },
    days() {
      let days = [];
      if (
        (this.month === 2 && this.year % 4 === 0 && this.year % 100 !== 0) ||
        (this.month === 2 && this.year % 400 === 0)
      ) {
        days = [...Array(29)].map((ele, i) => i + 1);
      } else if (this.month === 2) {
        days = [...Array(28)].map((ele, i) => i + 1);
      } else if (
        this.month === 4 ||
        this.month === 6 ||
        this.month === 9 ||
        this.month === 11
      ) {
        days = [...Array(30)].map((ele, i) => i + 1);
      } else {
        days = [...Array(31)].map((ele, i) => i + 1);
      }
      return days;
    },
  },

  data() {
    return {
      zero: true,
      first: false,
      firstSelected: false,
      plan: "",
      second: false,
      isChecked: true,

      last_name: "",
      first_name: "",
      last_name_kana: "",
      first_name_kana: "",
      gender: "",

      year: "",
      month: "",
      day: "",
      zipcode: "",
      address1: "",
      address2: "",
      address3: "",
      email_a: "",
      email_b: "",
      passwd_a: "",
      passwd_b: "",
      users: firebase.firestore().collection("users"),

      form: {
        email: "",
        name: "",
        food: null,
        checked: [],
      },
      foods: [
        { text: "Select One", value: null },
        "Carrots",
        "Beans",
        "Tomatoes",
        "Corn",
      ],
      show: true,
    };
  },
  mounted() {
    if (localStorage.plan) {
      this.plan = localStorage.plan;
      console.log("plan:" + this.plan);
    }
  },
  watch: {
    plan(newPlan) {
      console.log("plan:" + newPlan);
      localStorage.plan = newPlan;
    },
  },

  methods: {
    aaa(str) {
      console.log("aa:" + str);
    },
    onClick: function () {
      this.isChecked = !this.isChecked;
    },
    toHalfWidth(input) {
      return input.replace(/[！-～]/g, function (input) {
        return String.fromCharCode(input.charCodeAt(0) - 0xfee0);
      });
    },
    goPlan() {
      this.zero = false;
      this.first = true;
    },
    goSecond() {
      console.log("second!!!");
      this.first = false;
      this.firstSelected = this.second = !this.first;
    },

    backFirst() {
      console.log("second!!!");
      this.first = true;
      this.firstSelected = this.second = !this.first;
    },

    autoCompleteAddress(zipcode) {
      var zip = this.toHalfWidth(zipcode);
      this.axios
        .get(`https://api.zipaddress.net/?zipcode=${zip}`)
        .then((response) => {
          this.address1 = response.data.data.fullAddress;
          console.log("address:" + JSON.stringify(response.data));
        })
        .catch(() => {
          this.address1 = "";
        });
    },

    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    greeting: function () {
      this.axios
        .get("/apis/hello")
        .then((res) => alert(res.data))
        .catch((e) => alert(e));
    },

    changeErrorString(code) {
      switch (code) {
        case "auth/cancelled-popup-request":
        case "auth/popup-closed-by-user":
          return null;
        case "auth/email-already-in-use":
          return "このメールアドレスは使用されています";
        case "auth/invalid-email":
          return "メールアドレスの形式が正しくありません";
        case "auth/user-disabled":
          return "サービスの利用が停止されています";
        case "auth/user-not-found":
          return "メールアドレスまたはパスワードが違います";
        case "auth/user-mismatch":
          return "メールアドレスまたはパスワードが違います";
        case "auth/weak-password":
          return "パスワードは6文字以上にしてください";
        case "auth/wrong-password":
          return "メールアドレスまたはパスワードが違います";
        case "auth/popup-blocked":
          return "認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください";
        case "auth/operation-not-supported-in-this-environment":
        case "auth/auth-domain-config-required":
        case "auth/operation-not-allowed":
        case "auth/unauthorized-domain":
          return "現在この認証方法はご利用頂けません";
        case "auth/requires-recent-login":
          return "認証の有効期限が切れています";
        default:
          return "エラーが発生しました。しばらく時間をおいてお試しください";
      }
    },

    signUp() {
      /*
if(!this.address1){
  this.autoCompleteAddress(this.zipcode);

}*/

      const me = this;
      this.signupUser;
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email_a, this.passwd_a)
        .then((result) => {
          var additonalInfo = {};
          additonalInfo.last_name = this.last_name;
          additonalInfo.first_name = this.first_name;
          additonalInfo.last_name_kana = this.last_name_kana;
          additonalInfo.first_name_kana = this.first_name_kana;
          additonalInfo.gender = this.gender;
          additonalInfo.year = this.year;
          additonalInfo.month = this.month;
          additonalInfo.day = this.day;
          additonalInfo.zipcode = this.zipcode;
          additonalInfo.address1 = this.address1;
          additonalInfo.address2 = this.address2;
          additonalInfo.address3 = this.address3;
          additonalInfo.email_a = this.email_a;

          return this.users.doc(result.user.uid).set(additonalInfo);
        })
        .then((aaa) => {
          firebase
            .auth()
            .signInWithEmailAndPassword(this.email_a, this.passwd_a)
            .then((userlogin) => {
              userlogin = firebase.auth().currentUser;
              if (userlogin != null) {
                console.log("err");
              }
              this.$router.push("/id");
              return aaa;
            });

          //return result2
        })
        /*
        .then(function (next) {
          
        })
        */
        .catch((error) => {
          if (error) {
            if (error.code != "" && error.code != undefined) {
              me.isRegister = false;
              alert(this.changeErrorString(error.code));
            }
          }

          //alert(error.message);
        });

      //window.location.href = "./login/";
    },
  },
  /*
  components: {
    HelloWorld,
  },
  */
};
</script>

<style>
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
*/
</style>
