<template>
  <div id="app">
    <b-navbar type="light" variant="light">
      <b-navbar-brand
        ><img src="./assets/logo_everymobile.svg"
      /></b-navbar-brand>
      <b-button
        variant="outline-secondary"
        size="sm"
        pill
        href="https://www.everymobile.jp/"
        >サービスサイトに戻る</b-button
      ></b-navbar
    >

    <b-container justifiy-content-center v-if="zero">
      <br />
      <h4 class="text-center">
        <strong>お申し込みの前にご用意ください</strong>
      </h4>
      <br />

      <b-row align-h="center" cols="2" class="justify-content-md-center">
        <b-col cols="2" sm="1">
          <b-avatar variant="info" icon="person-badge" size="3em"></b-avatar>
        </b-col>
        <b-col cols="6" sm="5">
          <h5><b>1. 本人確認書類</b></h5>
          <p>お申し込みには本人確認書類が必要です。詳しくは<a href="https://www.everymobile.jp/verification/" target="_blank"
              >こちら</a
            >をご確認ください
          </p>
        </b-col>
      </b-row>

      <b-row align-h="center" cols="2" class="justify-content-md-center">
        <b-col cols="2" sm="1">
          <b-avatar variant="info" icon="envelope" size="3em"></b-avatar>
        </b-col>
        <b-col cols="6" sm="5">
          <h5><b>2. ご連絡用のメールアドレス</b></h5>
          <p>
            もしお持ちでない場合は、無料のメールアドレス(outloook.com)などをご取得ください。
          </p>
        </b-col>
      </b-row>
      <br />
      <div align="center">
        <b-button size="lg" pill variant="outline-success" @click="goPlan()"
          >プラン選択に進む</b-button
        >
      </div>
    </b-container>

    <b-container class="bv-example-row">
      <div id="1" v-if="first">
        <br />
        <h4 class="text-center">
          <strong>プランをご選択ください</strong>
        </h4>
        <br />
        <b-card header="プラン選択">
          <b-card bg-variant="light">
            <b-row>
              <b-col cols="4" sm="4">
                <div align="center">
                  <img alt="Vue logo" src="./assets/img1_md.png" width="100" />
                </div>
              </b-col>
              <b-col cols="8" sm="8">
                <div>
                データ容量 5G<br />
                国内いつでもかけ放題<br />
                </div>
              </b-col>
            </b-row>
          </b-card>

          <b-table-simple>
            <b-tr>
              <b-th class="text-right">プラン選択</b-th>
              <b-td>
                <b-form inline>
                  <div>
                    <b-form-radio-group
                      button-variant="primary"
                      size="lg"
                      name="radio-btn-outline"
                    >
                      <b-form-radio
                        v-model="plan"
                        name="radio-size"
                        size="lg"
                        value="d"
                      >
                        <b-card title="Dプラン">
                          <b-card-text>ドコモの通信エリアに対応</b-card-text>
                        </b-card>
                      </b-form-radio>
                      <b-form-radio
                        v-model="plan"
                        name="radio-size"
                        size="lg"
                        value="a"
                      >
                        <b-card title="Aプラン">
                          <b-card-text>auの通信エリアに対応 &emsp;&nbsp;</b-card-text>
                        </b-card>
                      </b-form-radio>
                    </b-form-radio-group>
                  </div>
                </b-form>
              </b-td>
            </b-tr>
          </b-table-simple>
        </b-card>
        <br />

        <div align="center">
          <b-button
            pill
            size="lg"
            variant="outline-success"
            v-if="plan"
            @click="goSecond()"
            >次に進む</b-button
          >
        </div>

        <!--
          <b-row align-h="center" cols="2" class="justify-content-md-center">
            <b-col sm="4">
<div align="center">
              <b>既にご利用の方</b><br>
              <b-button pill block size="lg" variant="outline-success" v-if="plan">マイページログイン</b-button>
              </div>

            </b-col>
            <b-col sm="4">

<div align="center">
              <b>新規でお申し込みの方</b><br>
              <b-button pill block size="lg" variant="outline-success" v-if="plan" @click="goSecond()">新規登録</b-button>
</div>
            </b-col>
          </b-row>
-->
      </div>

      <div id="1selected" v-if="firstSelected">
        <br />
        <h4 class="text-center">
          <strong>プランの選択</strong>
        </h4>
        <br />
        <b-card bg-variant="light">
          <b-row>
            <b-col cols="4" sm="4">
              <div align="center">
                <img alt="Vue logo" src="./assets/img1_md.png" width="100" />
              </div>
            </b-col>
            <b-col cols="8" sm="8">
              <div>
                データ容量 5G<br />
                国内いつでもかけ放題<br />
              </div>
            </b-col>
          </b-row>
        </b-card>
        <div v-if="plan == 'a'">
          <b-card title="Aプラン">
            <b-card-text>auの通信エリアに対応</b-card-text>
            <div align="right">
              <b-button
                pill
                size="sm"
                variant="outline-secondary"
                @click="backFirst()"
                >プランを変更する</b-button
              >
            </div>
          </b-card>
        </div>
        <div v-if="plan == 'd'">
          <b-card title="Dプラン">
            <b-card-text>ドコモの通信エリアに対応</b-card-text>
            <div align="right">
              <b-button
                pill
                size="sm"
                variant="outline-secondary"
                @click="backFirst()"
                >プランを変更する</b-button
              >
            </div>
          </b-card>
        </div>
        <br>
      <div>
          <b-row>
            <b-col sm>





              <b>はじめての方・everyIDをお持ちでない方</b><br><br>
                <b-button block
                pill
                size="lg"
                @click="goSignUp()"
                variant="outline-success">新規登録</b-button>
            </b-col>
            <b-col sm>
               <b>everyIDをお持ちの方</b><br><br>
                <b-button block
                pill
                size="lg"
                @click="goLogin()"
                variant="outline-success">everyIDでログイン</b-button>
           
            </b-col>
          </b-row>
          <br>

      </div>


      </div>

    </b-container>
    <br />
    <footer class="border-top">

      <br />
      <p align="center">&copy; everymobile inc.</p>
    </footer>
  </div>
</template>

<script>
import firebase from 'firebase/compat';
//import HelloWorld from "./components/HelloWorld.vue";
import { extend } from "vee-validate";
import { required, numeric, regex, confirmed } from "vee-validate/dist/rules";

extend("required", required);
extend("numeric", numeric);
extend("regex", regex);
extend("confirmed", confirmed);

export default {
  name: "App",
  computed: {
    inputType: function () {
      return this.isChecked ? "password" : "text";
    },
    iconType: function () {
      return this.isChecked ? "eye-slash" : "eye";
    },
    years() {
      const years = [];
      for (let year = 1900; year <= new Date().getFullYear(); year++) {
        years.push(year);
      }
      return years;
    },
    months() {
      const months = [...Array(12)].map((ele, i) => i + 1);
      return months;
    },
    days() {
      let days = [];
      if (
        (this.month === 2 && this.year % 4 === 0 && this.year % 100 !== 0) ||
        (this.month === 2 && this.year % 400 === 0)
      ) {
        days = [...Array(29)].map((ele, i) => i + 1);
      } else if (this.month === 2) {
        days = [...Array(28)].map((ele, i) => i + 1);
      } else if (
        this.month === 4 ||
        this.month === 6 ||
        this.month === 9 ||
        this.month === 11
      ) {
        days = [...Array(30)].map((ele, i) => i + 1);
      } else {
        days = [...Array(31)].map((ele, i) => i + 1);
      }
      return days;
    },
  },

  data() {
    return {
      zero: true,
      first: false,
      firstSelected: false,
      plan: "",
      second: false,
      isChecked: true,

      last_name: "",
      first_name: "",
      last_name_kana: "",
      first_name_kana: "",
      gender: "",

      year: "",
      month: "",
      day: "",
      zipcode: "",
      address1: "",
      address2: "",
      address3: "",
      email_a: "",
      email_b: "",
      passwd_a: "",
      passwd_b: "",
      users: firebase.firestore().collection("users"),

      form: {
        email: "",
        name: "",
        food: null,
        checked: [],
      },
      foods: [
        { text: "Select One", value: null },
        "Carrots",
        "Beans",
        "Tomatoes",
        "Corn",
      ],
      show: true
    };
  },
  mounted() {
    if (localStorage.plan) {
      this.plan = localStorage.plan;
      console.log("plan:"+this.plan)
    }
  },
  watch: {
    plan(newPlan) {
      console.log("plan:"+newPlan)
      localStorage.plan = newPlan;
    }
  },

  methods: {
    onClick: function () {
      this.isChecked = !this.isChecked;
    },
    goSignUp: function () {
      this.orderProgress()
            this.$router.push("/signup");
            return
    },
    goLogin: function () {
      this.orderProgress()
            this.$router.push("/login/order");
            return
    },
    orderProgress(){
      localStorage.onProgress = true;
    },


    toHalfWidth(input) {
      return input.replace(/[！-～]/g, function (input) {
        return String.fromCharCode(input.charCodeAt(0) - 0xfee0);
      });
    },
    goPlan() {
      this.zero = false;
      this.first = true;
    },
    goSecond() {
      console.log("second!!!");
      this.first = false;
      this.firstSelected = this.second = !this.first;
    },

    backFirst() {
      console.log("second!!!");
      this.first = true;
      this.firstSelected = this.second = !this.first;
    },

    autoCompleteAddress(zipcode) {
      var zip = this.toHalfWidth(zipcode);
      this.axios
        .get(`https://api.zipaddress.net/?zipcode=${zip}`)
        .then((response) => {
          this.address1 = response.data.data.fullAddress;
          console.log("address:" + JSON.stringify(response.data));
        })
        .catch(() => {
          this.address1 = "";
        });
    },

    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    greeting: function () {
      this.axios
        .get("/apis/hello")
        .then((res) => alert(res.data))
        .catch((e) => alert(e));
    },

    changeErrorString(code) {
      switch (code) {
        case "auth/cancelled-popup-request":
        case "auth/popup-closed-by-user":
          return null;
        case "auth/email-already-in-use":
          return "このメールアドレスは使用されています";
        case "auth/invalid-email":
          return "メールアドレスの形式が正しくありません";
        case "auth/user-disabled":
          return "サービスの利用が停止されています";
        case "auth/user-not-found":
          return "メールアドレスまたはパスワードが違います";
        case "auth/user-mismatch":
          return "メールアドレスまたはパスワードが違います";
        case "auth/weak-password":
          return "パスワードは6文字以上にしてください";
        case "auth/wrong-password":
          return "メールアドレスまたはパスワードが違います";
        case "auth/popup-blocked":
          return "認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください";
        case "auth/operation-not-supported-in-this-environment":
        case "auth/auth-domain-config-required":
        case "auth/operation-not-allowed":
        case "auth/unauthorized-domain":
          return "現在この認証方法はご利用頂けません";
        case "auth/requires-recent-login":
          return "認証の有効期限が切れています";
        default:
          return "エラーが発生しました。しばらく時間をおいてお試しください";
      }
    },



  },
  /*
  components: {
    HelloWorld,
  },
  */
};
</script>

<style>
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
*/

</style>
