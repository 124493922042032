<template>
  <div id="app">
    <b-navbar type="light" variant="light">
      <b-navbar-brand
        ><img src="./assets/logo_everymobile.svg"
      /></b-navbar-brand>
      <b-button
        variant="outline-secondary"
        size="sm"
        pill
        href="https://www.everymobile.jp/"
        >サービスサイトに戻る</b-button
      >
      
       <b-navbar-nav class="ml-auto">
               <b-button
        variant="outline-secondary"
        size="sm"
        pill
        @click="logout()"
        >ログアウト</b-button
      >
       </b-navbar-nav>
      
      </b-navbar
    >

    

    <b-container class="bv-example-row">

          <b-card bg-variant="light" v-if="!user.id_verified" header="本人確認書類をアップロードしてください。">


  <b-card-text>
    本人確認書類のアップロードが必要です。
  </b-card-text>
  <b-button href="#" variant="primary" @click="goId()">アップロードする</b-button>


          </b-card>
    
<br><br>
              <b-card bg-variant="light" header="マイページの機能につきまして">


  <b-card-text>
<p>現在マイページの準備中です。</p>
<p>お急ぎのご用件、お問い合わせにつきましては、<br>
エブリモバイル コンタクトセンター <b>03-6822-0944</b> までお問合せください。</p>
  </b-card-text>



          </b-card>
    
    
    
    
    </b-container>



      

    <br />
    <footer class="border-top">

      <br />
      <p align="center">&copy; everymobile inc.</p>
    </footer>
  </div>
</template>

<script>
import firebase from 'firebase/compat';
//import HelloWorld from "./components/HelloWorld.vue";
import { extend } from "vee-validate";
import { required, numeric, regex, confirmed } from "vee-validate/dist/rules";

extend("required", required);
extend("numeric", numeric);
extend("regex", regex);
extend("confirmed", confirmed);


export default {
  name: "App",
  metaInfo: {
    title: 'マイページ',
  },
  computed: {
    id_verified(){
      return this.user.id_verified;

    },
    user() {
      return this.$store.getters.user;
    },
    inputType: function () {
      return this.isChecked ? "password" : "text";
    },
    iconType: function () {
      return this.isChecked ? "eye-slash" : "eye";
    },
    years() {
      const years = [];
      for (let year = 1900; year <= new Date().getFullYear(); year++) {
        years.push(year);
      }
      return years;
    },
    months() {
      const months = [...Array(12)].map((ele, i) => i + 1);
      return months;
    },
    days() {
      let days = [];
      if (
        (this.month === 2 && this.year % 4 === 0 && this.year % 100 !== 0) ||
        (this.month === 2 && this.year % 400 === 0)
      ) {
        days = [...Array(29)].map((ele, i) => i + 1);
      } else if (this.month === 2) {
        days = [...Array(28)].map((ele, i) => i + 1);
      } else if (
        this.month === 4 ||
        this.month === 6 ||
        this.month === 9 ||
        this.month === 11
      ) {
        days = [...Array(30)].map((ele, i) => i + 1);
      } else {
        days = [...Array(31)].map((ele, i) => i + 1);
      }
      return days;
    },
  },

  data() {
    return {
      //id_verified:null,
      zero: true,
      first: false,
      firstSelected: true,
      plan: "",
      second: false,
      isChecked: true,

      last_name: "",
      first_name: "",
      last_name_kana: "",
      first_name_kana: "",
      gender: "",

      year: "",
      month: "",
      day: "",
      zipcode: "",
      address1: "",
      address2: "",
      address3: "",
      email_a: "",
      email_b: "",
      passwd_a: "",
      passwd_b: "",
      //users: firebase.firestore().collection("users"),

      form: {
        email: "",
        name: "",
        food: null,
        checked: [],
      },
      foods: [
        { text: "Select One", value: null },
        "Carrots",
        "Beans",
        "Tomatoes",
        "Corn",
      ],
      show: true
    };
  },
  mounted() {
  
    if (localStorage.plan) {
      this.plan = localStorage.plan;
      console.log("plan:"+this.plan)
    }
  },
  watch: {
    id_verified(){
      console.log("watch-verify")
      this.redirectId()
    },
    plan(newPlan) {
      console.log("plan:"+newPlan)
      localStorage.plan = newPlan;
    }
  },

  methods: {
  applicationCompleted(){




  },
  async redirectId(){
    console.log("mounted")
    //var  u = await this.$store.getters.user;
    if(this.id_verified === null || this.id_verified === undefined ){
      console.log("IDDDD")
      this.$router.push("/id");
    }else{
      console.log("are:"+this.id_verified)
    }
  },
    onClick: function () {
      this.isChecked = !this.isChecked;
    },
    goSignUp: function () {
            this.$router.push("/signup");
            return
    },
    goLogin: function () {
            this.$router.push("/login");
            return
    },
    goId: function () {
            this.$router.push("/id");
            return
    },
    logout(){
      firebase.auth().signOut().then(()=>{
  })
    },


    toHalfWidth(input) {
      return input.replace(/[！-～]/g, function (input) {
        return String.fromCharCode(input.charCodeAt(0) - 0xfee0);
      });
    },
    goPlan() {
      this.zero = false;
      this.first = true;
    },
    goSecond() {
      console.log("second!!!");
      this.first = false;
      this.firstSelected = this.second = !this.first;
    },

    backFirst() {
      console.log("second!!!");
      this.first = true;
      this.firstSelected = this.second = !this.first;
    },

    autoCompleteAddress(zipcode) {
      var zip = this.toHalfWidth(zipcode);
      this.axios
        .get(`https://api.zipaddress.net/?zipcode=${zip}`)
        .then((response) => {
          this.address1 = response.data.data.fullAddress;
          console.log("address:" + JSON.stringify(response.data));
        })
        .catch(() => {
          this.address1 = "";
        });
    },

    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    greeting: function () {
      this.axios
        .get("/apis/hello")
        .then((res) => alert(res.data))
        .catch((e) => alert(e));
    },

    changeErrorString(code) {
      switch (code) {
        case "auth/cancelled-popup-request":
        case "auth/popup-closed-by-user":
          return null;
        case "auth/email-already-in-use":
          return "このメールアドレスは使用されています";
        case "auth/invalid-email":
          return "メールアドレスの形式が正しくありません";
        case "auth/user-disabled":
          return "サービスの利用が停止されています";
        case "auth/user-not-found":
          return "メールアドレスまたはパスワードが違います";
        case "auth/user-mismatch":
          return "メールアドレスまたはパスワードが違います";
        case "auth/weak-password":
          return "パスワードは6文字以上にしてください";
        case "auth/wrong-password":
          return "メールアドレスまたはパスワードが違います";
        case "auth/popup-blocked":
          return "認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください";
        case "auth/operation-not-supported-in-this-environment":
        case "auth/auth-domain-config-required":
        case "auth/operation-not-allowed":
        case "auth/unauthorized-domain":
          return "現在この認証方法はご利用頂けません";
        case "auth/requires-recent-login":
          return "認証の有効期限が切れています";
        default:
          return "エラーが発生しました。しばらく時間をおいてお試しください";
      }
    },



  },
  /*
  components: {
    HelloWorld,
  },
  */
};
</script>

<style>
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
*/

</style>
