<template>
  <div id="app">

    <b-navbar type="light" variant="light">
      <b-navbar-brand
        ><img src="./assets/logo_everymobile.svg"
      /></b-navbar-brand>
      <b-button
        variant="outline-secondary"
        size="sm"
        pill
        href="https://www.everymobile.jp/"
        >サービスサイトに戻る</b-button
      ></b-navbar
    >
    

    <b-container class="bv-example-row">

      <br>
      <b-card header="基本情報の入力">
      <b-row>
        <b-col>
          <b-table-simple>
            <b-tr>
              <b-th class="text-right">ご契約者名(漢字)</b-th>
              <b-td>
                <b-form inline>
                  <b-row>
                    <b-col cols="6" sm="6">
                      <label class="sr-only" for="inline-form-input-name"
                        >姓</label
                      >
                      <b-form-input
                        id="inline-form-input-name"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        placeholder="Jane Doe"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="6">
                      <label class="sr-only" for="inline-form-input-name"
                        >名</label
                      >
                      <b-form-input
                        id="inline-form-input-username"
                        placeholder="Username"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-form>
              </b-td>
            </b-tr>

            <b-tr>
              <b-th class="text-right">ご契約者名(カナ)</b-th>
              <b-td>
                <b-form inline>
                  <b-row>
                    <b-col cols="6" sm="6">
                      <label class="sr-only" for="inline-form-input-name"
                        >姓</label
                      >
                      <b-form-input
                        id="inline-form-input-name"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        placeholder="Jane Doe"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="6">
                      <label class="sr-only" for="inline-form-input-name"
                        >名</label
                      >
                      <b-form-input
                        id="inline-form-input-username"
                        placeholder="Username"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-form>
              </b-td>
            </b-tr>

            <b-tr>
              <b-th class="text-right">ご契約者名(ローマ字)</b-th>
              <b-td>
                <b-form inline>
                  <b-row>
                    <b-col cols="6" sm="6">
                      <label class="sr-only" for="inline-form-input-name"
                        >姓</label
                      >
                      <b-form-input
                        id="inline-form-input-name"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        placeholder="Jane Doe"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="6">
                      <label class="sr-only" for="inline-form-input-name"
                        >名</label
                      >
                      <b-form-input
                        id="inline-form-input-username"
                        placeholder="Username"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-form>
              </b-td>
            </b-tr>

            <b-tr>
              <b-th class="text-right">性別</b-th>
              <b-td>
                <b-form inline>
                  <div>
                    <b-form-radio-group
                      button-variant="outline-primary"
                      size="lg"
                      name="radio-btn-outline"
                      buttons
                    >
                      <b-form-radio
                        v-model="gender"
                        name="radio-size"
                        size="lg"
                        value="male"
                      >
                        男性</b-form-radio
                      >
                      <b-form-radio
                        v-model="gender"
                        name="radio-size"
                        size="lg"
                        value="female"
                      >
                        女性</b-form-radio
                      >
                    </b-form-radio-group>
                  </div>
                </b-form>
              </b-td>
            </b-tr>

            <b-tr>
              <b-th class="text-right">生年月日</b-th>
              <b-td>
                <b-row>
                  <b-col sm="3" xs="8"
                    ><b-form-select
                      v-model="year"
                      :options="years"
                    ></b-form-select
                  ></b-col>
                  <b-col sm="1" xs="4">年</b-col>
                  <b-col sm="3" xs="8"
                    ><b-form-select
                      v-model="month"
                      :options="months"
                    ></b-form-select
                  ></b-col>
                  <b-col sm="1" xs="4">月</b-col>
                  <b-col sm="3" xs="8"
                    ><b-form-select
                      v-model="day"
                      :options="days"
                    ></b-form-select
                  ></b-col>
                  <b-col sm="1" xs="4">日</b-col>
                </b-row>
              </b-td>
            </b-tr>

            <b-tr>
              <b-th class="text-right">住所</b-th>
              <b-td>
                                  <label for="">郵便番号:</label>
                    <b-input-group prepend="〒" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input type="text" prepend="〒" v-model="zipcode" placeholder="000-0000"></b-form-input>
                    <b-input-group-append>
                <b-button @click="autoCompleteAddress(zipcode)" variant="primary">住所自動入力</b-button>
    </b-input-group-append>

    </b-input-group>
<br>
<label for="address1">都道府県/市区町村:</label>
                <b-form-input type="text"  id="address1" v-model="address" readonly></b-form-input>
<br>
<label for="address1">丁目番地:</label>

                <b-form-input type="text" placeholder="例) 1-1-1" v-model="address2"></b-form-input>
<br>
<label for="address1">マンション名/部屋番号:</label>
                <b-form-input type="text" placeholder="例) 〇〇マンション101号室" v-model="address2"></b-form-input>
<br>



              </b-td>
            </b-tr>

            <b-tr>
              <b-th class="text-right">メールアドレス</b-th>
              <b-td>
                                  
                <b-form-input type="text" v-model="email1" placeholder="例) every@everymobile.jp"></b-form-input>
<br>
<label for="address1">確認のため再度入力してください</label>

                <b-form-input type="text" v-model="email2" placeholder="例) every@everymobile.jp"></b-form-input>

              </b-td>
            </b-tr>


            <b-tr>
              <b-th class="text-right">エブリIDの登録</b-th>
              <b-td>
                <p>マイページのご利用に必要なIDです。お好きなIDを入力の上、登録可能かどうかご確認ください。</p>
                                  
                    <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input type="text" v-model="everyid"></b-form-input>
                    <b-input-group-append>
                <b-button @click="autoCompleteAddress(zipcode)" variant="primary">登録可能か確認</b-button>
    </b-input-group-append>
    </b-input-group>

              </b-td>
            </b-tr>

            <b-tr>
              <b-th class="text-right">パスワード</b-th>
              <b-td>
                <p>マイページのご利用に必要なパスワードです</p>
                                  
<b-form-input type="text" v-model="password1"></b-form-input>
<br>
<label for="address1">確認のため再度入力してください</label>

                <b-form-input type="text" v-model="password2"></b-form-input>

              </b-td>
            </b-tr>




<!--
            <b-tr>
              <b-td>
                <b-form-group
                  id="input-group-1"
                  label="Email address:"
                  label-for="input-test"
                  description="We'll never share your email with anyone else."
                >
                  <b-form-input
                    label="Email address:"
                    id="inoput-test"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Jane Doe"
                  ></b-form-input>
                </b-form-group>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>
                <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                  <b-form-group
                    id="input-group-1"
                    label="Email address:"
                    label-for="input-1"
                    description="We'll never share your email with anyone else."
                  >
                    <b-form-input
                      id="input-1"
                      v-model="form.email"
                      type="email"
                      placeholder="Enter email"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    label="Your Name:"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="form.name"
                      placeholder="Enter name"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input-group-3"
                    label="Food:"
                    label-for="input-3"
                  >
                    <b-form-select
                      id="input-3"
                      v-model="form.food"
                      :options="foods"
                      required
                      button
                    >
                    </b-form-select>
                  </b-form-group>

                  <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      v-model="form.checked"
                      id="checkboxes-4"
                      :aria-describedby="ariaDescribedby"
                    >
                      <b-form-checkbox value="me">Check me out</b-form-checkbox>
                      <b-form-checkbox value="that"
                        >Check that out</b-form-checkbox
                      >
                    </b-form-checkbox-group>
                  </b-form-group>

                  <b-button type="submit" variant="primary">Submit</b-button>
                  <b-button type="reset" variant="danger">Reset</b-button>
                </b-form>
              </b-td>
            </b-tr>
            -->
          </b-table-simple>
        </b-col>
      </b-row>
      </b-card>

            <b-container fluid>
              <b-form-group label="添付ファイル(1ファイルのみ)" label-for="textarea-formatter" description class="mb-0">
                <b-form-file ref="file-input" browse-text="ファイルを選択" @change="selectFile" placeholder="ファイルを選択するか、ここにドロップしてください。" accept=".jpg, .png, .gif, .pdf, .zip, .xls, .xlsx, .doc, .docx, .ppt, .pptx"></b-form-file>
                </b-form-group>
            </b-container>


    </b-container>
  </div>
</template>

<script>
//import HelloWorld from "./components/HelloWorld.vue";
import firebase from 'firebase/compat';

export default {
  name: "App",
  computed: {
    years() {
      const years = []
      for (let year = 1900; year <= new Date().getFullYear(); year++) {
        years.push(year)
      }
      return years
    },
    months() {
      const months = [...Array(12)].map((ele, i) => i + 1)
      return months
    },
    days() {
      let days = []
      if ((this.month === 2 && this.year % 4 === 0 && this.year % 100 !== 0) || (this.month === 2 && this.year % 400 === 0)) {
        days = [...Array(29)].map((ele, i) => i + 1)
      } else if (this.month === 2) {
        days = [...Array(28)].map((ele, i) => i + 1)
      } else if (this.month === 4 || this.month === 6 || this.month === 9 || this.month === 11) {
        days = [...Array(30)].map((ele, i) => i + 1)
      } else {
        days = [...Array(31)].map((ele, i) => i + 1)
      }
      return days
    },
  },

  data() {
    return {
      year: '',
    month: '',
    day: '',
        zipcode: '',
    address: '',
      gender: "",
      form: {
        email: "",
        name: "",
        food: null,
        checked: [],
      },
      foods: [
        { text: "Select One", value: null },
        "Carrots",
        "Beans",
        "Tomatoes",
        "Corn",
      ],
      show: true,
    };
  },

  methods: {

      selectFile(e) {
            e.preventDefault();

            let files = e.target.files;
            if (!files[0]) {
                this.upload_file_name = null;
                return null;
            }

            var size = files[0].size;


            //上限サイズは3MB
            if (size > 20000000) { //3000000
                this.uploaderrors = "ファイルの上限サイズ20MBを超えています"
                window.alert("ファイルの上限サイズ20MBを超えています");
                e.currentTarget.value = ''
                return null;

            } else {

                this.uploaderrors = ""
                this.uploadFile = files[0];
                this.upload();
            }
        },
        upload() {
            var me = this;
            if (!this.uploadFile) {
                this.infoMsg = '選択してください'
                return;
            }
            //this.upload_file_name = "upload/" + "aaa" + "/" + this.current_user.uid + "/" + this.uploadFile.name;
            this.upload_file_name = "upload/" + "aaa" + "/" + "hoge" + "/" + this.uploadFile.name;
            var storageRef = firebase.storage().ref().child(this.upload_file_name);
            var uploadTask = storageRef.put(this.uploadFile);

            uploadTask.on('state_changed', function(snapshot) {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                    if (progress == 100) {
                        me.uploading = false;
                        //console.log('Upload is ' + progress + '% done');
                    } else {
                        //console.log('Upload is ' + progress + '% done');
                        me.uploading = true;
                    }
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            //console.log('Upload is paused');
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            //console.log('Upload is running');
                            break;
                    }
                }, function(error) {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            window.alert("添付ファイル確認中にエラーが発生しました。再度ログインしてください");
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;
                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                    }
                },

            );
        },



autoCompleteAddress(zipcode){
    this.axios.get(`https://api.zipaddress.net/?zipcode=${zipcode}`)
    .then((response)=>{
      this.address = response.data.data.fullAddress
      console.log("address:"+ JSON.stringify(response.data))
    })
    .catch(() => {
      this.address = ''
    })
  },

    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    greeting: function () {
      this.axios
        .get("/apis/hello")
        .then((res) => alert(res.data))
        .catch((e) => alert(e));
    },
  },
  /*
  components: {
    HelloWorld,
  },
  */
};
</script>

<style>
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
*/

</style>
