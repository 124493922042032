<template>
  <div id="app">
    <b-navbar type="light" variant="light">
      <b-navbar-brand
        ><img src="./assets/logo_everymobile.svg"
      /></b-navbar-brand>
</b-navbar
    >

    <b-container class="bv-example-row">
      <br />
      <h4 class="text-center">
        <strong>本人確認書類のアップロード</strong>
      </h4>
      <br />
      <h5>注意事項</h5>

      <ul>
        <li>
          住民票の本籍地や個人番号（マイナンバー）の箇所を除く、画像加工（マスキング等）が確認された場合、お申し込みをお受けできない可能性がございます。
        </li>
        <li>
          画像が不鮮明な場合や不備があった場合には、再度ご提出をお願いすることがございます。
        </li>
        <li>
          本人確認書類の住所が最新のものではない場合、補助書類を併せてご提出いただく必要があります。
        </li>
      </ul>

      <b-card
        v-if="idtype == null"
        header="ご本人確認書類の種類を選択してください"
      >
        <b-form-select v-model="idtype" :options="idCardType"></b-form-select>
      </b-card>

      <b-card v-else>
        <strong>{{ idtype.name }}</strong
        >&emsp;<b-button
          variant="outline-secondary"
          @click="
            idtype = null;
            needAdditionalDoc = false;
          "
          >種類を変更する</b-button
        >
      </b-card>

      <br />
      <b-card v-if="idtype != null" :header="getIdTitle()">
        <ol>
          <li>
            氏名、住所、生年月日がお申し込みいただいた内容と同じであること。
            <div v-if="idtype.type == '005'">
              ※住所記載がない場合は必ず補助書類の画像もアップロードしてください。<br />
              ※再交付日や有効期限の記載がある場合は期限内であること。
            </div>
            <div v-if="idtype.type == '011'">
              ※本人確認書類に記載されている住所と現住所が異なる場合は、あらかじめ個人番号カード(マイナンバーカード)の住所変更手続きをお願いします。
            </div>
          </li>
          <li v-if="idtype.type != '010' && idtype.type != '005'">
            有効期限内であること。
          </li>
          <li v-if="idtype.type == '010'">
            交付年月日が平成24年4月1日以降であること。
          </li>
          <li v-if="idtype.type == '001' || idtype.type == '010'">
            公安印が鮮明に映っていること。
          </li>
          <li v-if="idtype.type == '010' || idtype.type == '011'">
            顔写真が確認できること。
          </li>
          <li v-if="idtype.type == '004'">
            ※お申し込みの住所が表面のものと異なる場合必ず裏面の画像もアップロードしてください。
          </li>
          <li v-if="idtype.type == '002'">
            健康保険証本体と下記いずれかの補助書類をあわせてアップロードしてください。<br />
            &emsp;・公共料金領収書<br />
            &emsp;・住民票<br />
            &emsp;・届出避難場所証明書<br />
            ※お申し込みいただいた氏名・住所、健康保険証に記載の氏名・住所、補助書類に記載の氏名・住所が全て一致していることをご確認ください。
          </li>
          <li v-if="idtype.type == '003'">
            日本国パスポート本体と下記いずれかの補助書類をあわせてアップロードしてください。<br />
            &emsp;・公共料金領収書<br />
            &emsp;・住民票<br />
            &emsp;・届出避難場所証明書<br />
            ※お申し込みいただいた氏名・住所、日本国パスポートに記載の氏名・住所、補助書類に記載の氏名・住所が全て一致していることをご確認ください。
          </li>

          <li v-if="idtype.type == '002'">
            ※カード型の健康保険証の場合、裏面に住所が記載されているかご確認の上アップロードしてください。
          </li>
          <li v-if="idtype.type == '002'">
            ※紙型の保険証の場合、被扶養者はご自身の氏名が記載されている部分の画像もアップロードしてください。
          </li>
          <li v-if="idtype.type == '003'">
            ※所持人記入欄に氏名および住所が記入されていることをご確認の上アップロードしてください。
          </li>
          <li v-if="idtype.type == '004'">写真付きのカードであること。</li>
          <li
            v-if="
              idtype.type == '001' ||
              idtype.type == '007' ||
              idtype.type == '008' ||
              idtype.type == '009' ||
              idtype.type == '010'
            "
          >
            ※裏面の記載有無に関わらず、必ず裏面の画像もアップロードしてください。
          </li>
          <li v-if="idtype.type == '008' || idtype.type == '009'">
            ※在留期間が分割払い契約期間に満たない場合、分割払いをご利用できない場合があります。
          </li>
          <li v-if="idtype.type == '004'">
            住民基本台帳カード本体と補助書類をあわせてアップロードしてください。<br />
            ※お申し込みいただいた氏名・住所、住民基本台帳カードに記載の氏名・住所、補助書類に記載の氏名・住所が全て一致していることをご確認ください。
          </li>
          <li v-if="idtype.type == '011'">
            "表面"の画像のみアップロードしていること。<br />
            ※裏面の画像がアップロードされた場合、弊社にてデータを破棄しますので、再度ご提出をお願いします。<br />
            ※マイナンバー通知カードは本人確認書類・補助書類として受付できません。
          </li>
        </ol>

        <b-container fluid>
          <!--
          <b-form-file
            type="file"
            accept="image/*"
            @change="selectFile"
            capture="camera"
          >
          </b-form-file>
-->

          <b-row>
            <b-col sm>
              <b-img fluid v-if="files1" :src="url1"></b-img>

              <b-form-group
                :label="getUpFile(0)"
                label-for="textarea-formatter"
                description
                class="mb-0"
              >
                <b-form-file
                  ref="file-input"
                  :browse-text="getUploadText()"
                  @change="selectFile"
                  placeholder="ファイルを選択するか、ここにドロップしてください。"
                  accept=".jpg, .png, .gif"
                ></b-form-file>
              </b-form-group>
            </b-col>
            <b-col sm>
              <b-img fluid v-if="files2" :src="url2"></b-img>

              <b-form-group
                :label="getUpFile(1)"
                label-for="textarea-formatter"
                description
                class="mb-0"
              >
                <b-form-file
                  ref="file-input"
                  :browse-text="getUploadText()"
                  @change="selectFile2"
                  placeholder="ファイルを選択するか、ここにドロップしてください。"
                  accept=".jpg, .png, .gif"
                ></b-form-file>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-card>
      <br />

      <!--
<b-card v-if="idtype != null && idtype.type!='002' && idtype.type!='003' && idtype.type!='004' && idtype.type!='011'" :header="getAdditionalDocTitle()">
-->
      <b-card v-if="checkAdditionalDoc()" :header="getAdditionalDocTitle()">
        <b-form-radio-group
          label="Individual radios"
          name="radio-inline"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio
            v-model="needAdditionalDoc"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            value=false
            >特に変更はない</b-form-radio
          >
          <b-form-radio
            v-model="needAdditionalDoc"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            value=true
            >変更している</b-form-radio
          >
        </b-form-radio-group>
      </b-card>

      <br />
      <div
        v-if="
          (needAdditionalDoc == 'true' && checkAdditionalDoc() && idtype) ||
          (!checkAdditionalDoc() && idtype)">
        <p>
          あわせて補助書類のご提出をお願いいたします。<br />
          補助書類の種別を選択してください。
        </p>
        <b-card>
          <b-form-select
            v-model="additionalIdType"
            :options="idAdditionalCardType"
          ></b-form-select>
        </b-card>
      </div>

      <div v-if="additionalIdType">
        <br />
        <b-card>
          <b-container fluid>
            <!--
          <b-form-file
            type="file"
            accept="image/*"
            @change="selectFile"
            capture="camera"
          >
          </b-form-file>
-->

            <b-row>
              <b-col sm>
                <b-img fluid v-if="files3" :src="url3"></b-img>

                <b-form-group
                  :label="getAdditonalUpFile(0)"
                  label-for="textarea-formatter"
                  description
                  class="mb-0"
                >
                  <b-form-file
                    ref="file-input"
                    :browse-text="getUploadText()"
                    @change="selectFile3"
                    placeholder="ファイルを選択するか、ここにドロップしてください。"
                    accept=".jpg, .png, .gif"
                  ></b-form-file>
                </b-form-group>
              </b-col>
              <b-col sm>
                <!--
              <b-img fluid v-if="files2" :src="url2"></b-img>

              <b-form-group
                :label=getUpFile(1)
                label-for="textarea-formatter"
                description
                class="mb-0"
              >
                <b-form-file
                  ref="file-input"
                  :browse-text=getUploadText()
                  @change="selectFile2" 
                  placeholder="ファイルを選択するか、ここにドロップしてください。"
                  accept=".jpg, .png, .gif, .pdf, .zip, .xls, .xlsx, .doc, .docx, .ppt, .pptx"
                ></b-form-file>
              </b-form-group>
              -->
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </div>

      <br />

      <div v-if="idtype">
        <b-button block variant="outline-success" @click="checkAndUpload()"
          >アップロードする <b-icon icon="chevron-right"></b-icon
        ></b-button>
      </div>

      <b-modal v-model="finalCheck">エラー{{fileCompleted}}</b-modal>
    </b-container>
    <br>
    <br>
    <footer class="border-top">

      <br />
      <p align="center">&copy; everymobile inc.</p>
    </footer>
  </div>
</template>

<script>
//import HelloWorld from "./components/HelloWorld.vue";
import firebase from "firebase/compat";

export default {
  name: "App",
  computed: {
    user() {
      return this.$store.getters.user;
    },
    id_verified() {
      console.log("this.user:"+this.$store.getters.user);
      return this.$store.getters.user.id_verified;
    },
    tryCount() {
      if (this.$store.getters.user.try_count == null) {
        return 0;
      } else {
        return this.$store.getters.user.try_count;
      }
    }
  },
  watch: {
    id_verified() {
      console.log("watch-verify");
      this.redirectId();
    },
  },

  data() {
    return {
      finalCheck: null,
      fileCompleted: false,
      uid: firebase.auth().currentUser.uid,
      //tryCount: '',
      needAdditionalDoc: false,
      additionalIdType: null,
      idtype: null,
      files1: null,
      files2: null,
      files3: null,
      uploadFile1: null,
      uploadFile2: null,
      uploadFile3: null,
      url1: null,
      url2: null,
      url3: null,

      uploaded: [false, false, false, false, false],

      idCardType: [
        { value: null, text: "--選択してください--" },
        {
          value: { name: "運転免許証", type: "001", required: 2 },
          text: "運転免許証",
        },
        {
          value: { name: "運転経歴証明書", type: "010", required: 2 },
          text: "運転経歴証明書",
        },
        {
          value: {
            name: "個人番号カード(マイナンバーカード)",
            type: "011",
            required: 1,
          },
          text: "個人番号カード(マイナンバーカード)",
        },
        {
          value: { name: "健康保険証", type: "002", required: 1 },
          text: "健康保険証＋補助書類",
        },
        {
          value: { name: "パスポート", type: "003", required: 1 },
          text: "パスポート＋補助書類",
        },
        {
          value: {
            name: "住民基本台帳カード",
            type: "004",
            required: 1,
          },
          text: "住民基本台帳カード＋補助書類",
        },
        {
          value: { name: "身体障がい者手帳", type: "005", required: 1 },
          text: "身体障がい者手帳",
        },
        {
          value: { name: "精神障がい者保健福祉手帳", type: "006", required: 1 },
          text: "精神障がい者保健福祉手帳",
        },
        {
          value: { name: "特別永住者証明書", type: "007", required: 2 },
          text: "特別永住者証明書",
        },
        {
          value: { name: "外国人登録証明書", type: "008", required: 2 },
          text: "外国人登録証明書",
        },
        {
          value: { name: "在留カード", type: "009", required: 2 },
          text: "在留カード",
        },
      ],

      idAdditionalCardType: [
        { value: null, text: "--選択してください--" },
        {
          value: { name: "公共料金領収書", type: "031", required: 2 },
          text: "公共料金領収書",
        },
        {
          value: { name: "住民票", type: "032", required: 2 },
          text: "住民票",
        },
        {
          value: {
            name: "届出避難場所証明書",
            type: "033",
            required: 1,
          },
          text: "届出避難場所証明書",
        },
      ],

    };
  },

  methods: {
    async redirectId() {
      console.log("mounted");
      //var  u = await this.$store.getters.user;
      if (this.id_verified > 0) {
        console.log("IDDDD");
        this.$router.push("/");
      } else {
        console.log("are:" + this.id_verified);
      }
    },
idVerify(up_data){
  var me = this;
      const auth = firebase.app().functions('asia-northeast1').httpsCallable('idverify')
      auth(up_data).then(function(result) {
        console.log(result.data)
        if(localStorage.onProgress){
        me.$router.push("/order");
        }else{
        me.$router.push("/");
        }
      }).catch(err => {
        console.error(err)
      })
    },


    uploadedCheck(slot) {
      this.uploaded[slot] = true;

      console.log("uploaedCheck:" + slot);
      console.log("uploaedCheck:" + this.uploaded);
    },

    checkAndUpload() {
      var canUpload = false;
      console.log("required:" + this.idtype.required);
      if (this.idtype.required == 1) {
        if (this.uploaded[1]) {
          canUpload = true;
        }
      } else {
        if (this.uploaded[1] && this.uploaded[2]) {
          canUpload = true;
        }
      }

      if (this.checkAdditionalDoc() && this.needAdditionalDoc=='true') {
        console.log("need:"+this.needAdditionalDoc);
        console.log("fee:"+this.uploaded[3])
        if (!this.uploaded[3]) {
          canUpload = false;
        }
      }
      if (!this.checkAdditionalDoc()) {
        if (!this.uploaded[3]){
          canUpload = false;
        }
      }

      this.fileCompleted = canUpload;
     
      var up_data = {};
      up_data.tryCount = this.tryCount;
      up_data.idtype = this.idtype;
      up_data.additionalIdType = this.additionalIdType;
      up_data.image1 = this.uploadFile1 ? this.uploadFile1.name : null;
      up_data.image2 = this.uploadFile2 ? this.uploadFile2.name : null;
      up_data.image3 = this.uploadFile3 ? this.uploadFile3.name : null;

      if(canUpload){
        this.idVerify(up_data)
      }else{
         this.finalCheck = true;
      }
    },
    checkAdditionalDoc() {
      var value = false;
      if (this.idtype) {
        if (
          this.idtype != null &&
          this.idtype.type != "002" &&
          this.idtype.type != "003" &&
          this.idtype.type != "004" &&
          this.idtype.type != "011"
        ) {
          value = true;
        } else {
          value = false;
        }
      }
      return value;
    },
    getAdditionalDocTitle() {
      return this.idtype.name + "の氏名住所の変更はございませんか？";
    },
    getIdTitle() {
      return this.idtype.name + "についてご確認ください";
    },
    getAdditionalIdTitle() {
      return this.additionalIdType.name + "についてご確認ください";
    },
    getAdditonalUpFile() {
      return this.additionalIdType.name + "を選択してください";
    },

    getUpFile(side) {
      var noteText = "";
      if (this.idtype.type == "003") {
        noteText = side ? "所持人記入欄のページ" : "顔写真のページ";
      } else {
        noteText = side ? "裏面" : "表面";
      }
      return this.idtype.name + "の" + noteText + "を選択してください";
    },
    getUploadText() {
      var uptext = "";
      var ua = navigator.userAgent;
      if (
        ua.indexOf("iPad") >= 0 ||
        ua.indexOf("iPhone") >= 0 ||
        ua.indexOf("iPod") >= 0 ||
        ua.indexOf("Android") >= 0 ||
        ua.indexOf("Windows Phone") >= 0
      ) {
        uptext = "写真を撮影またはファイルを選択";
      } else {
        uptext = "ファイルを選択";
      }
      return uptext;
    },

    selectFile(e) {
      e.preventDefault();
      //console.log("aaaa:"+aaaa);

      this.files1 = e.target.files;
      if (!this.files1[0]) {
        this.upload_file_name = null;
        return null;
      }

      var size = this.files1[0].size;

      //上限サイズは3MB
      if (size > 20000000) {
        //3000000
        this.uploaderrors = "ファイルの上限サイズ20MBを超えています";
        window.alert("ファイルの上限サイズ20MBを超えています");
        e.currentTarget.value = "";
        return null;
      } else {
        this.uploaderrors = "";
        this.uploadFile1 = this.files1[0];
        this.upload(this.uploadFile1, "1");
        this.url1 = URL.createObjectURL(this.uploadFile1);
      }
    },
    selectFile2(e) {
      e.preventDefault();

      this.files2 = e.target.files;
      if (!this.files2[0]) {
        this.upload_file_name = null;
        return null;
      }

      var size = this.files2[0].size;

      //上限サイズは3MB
      if (size > 20000000) {
        //3000000
        this.uploaderrors = "ファイルの上限サイズ20MBを超えています";
        window.alert("ファイルの上限サイズ20MBを超えています");
        e.currentTarget.value = "";
        return null;
      } else {
        this.uploaderrors = "";
        this.uploadFile2 = this.files2[0];
        this.upload(this.uploadFile2, "2");
        this.url2 = URL.createObjectURL(this.uploadFile2);
      }
    },
    selectFile3(e) {
      e.preventDefault();

      this.files3 = e.target.files;
      if (!this.files3[0]) {
        this.upload_file_name = null;
        return null;
      }

      var size = this.files3[0].size;

      //上限サイズは3MB
      if (size > 20000000) {
        //3000000
        this.uploaderrors = "ファイルの上限サイズ20MBを超えています";
        window.alert("ファイルの上限サイズ20MBを超えています");
        e.currentTarget.value = "";
        return null;
      } else {
        this.uploaderrors = "";
        this.uploadFile3 = this.files3[0];
        this.upload(this.uploadFile3, "3");
        this.url3 = URL.createObjectURL(this.uploadFile3);
      }
    },

    upload(target, upload_slot) {
      var me = this;
      if (!target) {
        this.infoMsg = "選択してください";
        return;
      }

      //this.upload_file_name = "upload/" + "aaa" + "/" + this.current_user.uid + "/" + this.uploadFile.name;
      this.upload_file_name =
        "id/" +
        this.uid +
        "/" +
        this.tryCount +
        "/" +
        upload_slot +
        "_" +
        target.name;
      console.log("target:" + target.name);
      var storageRef = firebase.storage().ref().child(this.upload_file_name);
      var uploadTask = storageRef.put(target);

      uploadTask.on(
        "state_changed",
        function (snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          if (progress == 100) {
            me.uploading = false;
            me.uploadedCheck(upload_slot);
            //console.log('Upload is ' + progress + '% done');
          } else {
            //console.log('Upload is ' + progress + '% done');
            me.uploading = true;
          }
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log('Upload is running');
              break;
          }
        },
        function (error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              window.alert(
                "添付ファイル確認中にエラーが発生しました。再度ログインしてください"
              );
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        }
      );
    },

    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },

    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    greeting: function () {
      this.axios
        .get("/apis/hello")
        .then((res) => alert(res.data))
        .catch((e) => alert(e));
    },
  },
  /*
  components: {
    HelloWorld,
  },
  */
};
</script>

<style>
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
*/
</style>
