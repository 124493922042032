import Vue from 'vue'
import VueRouter from "vue-router";
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import router from "./routes/routes";
import store from "./store";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat';
//import { initializeApp, getApp } from 'firebase/app'
import { getApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getAuth, connectAuthEmulator } from 'firebase/auth'

//import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC_MvKJhxglzDjKIcWe1bqwJpD3WxEb-iM",
  authDomain: "every-olsu.firebaseapp.com",
  projectId: "every-olsu",
  storageBucket: "every-olsu.appspot.com",
  messagingSenderId: "673866690470",
  appId: "1:673866690470:web:36803f0f0ab22cc42a2967",
  measurementId: "G-5K23X701GV"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//initializeApp(firebaseConfig)

//logEvent(analytics, 'notification_received');

const isEmulating = window.location.hostname === 'localhost'
if (isEmulating) {
  const auth = getAuth()
  connectAuthEmulator(auth, 'http://localhost:9099')
  const functions = getFunctions(getApp())
  connectFunctionsEmulator(functions, 'localhost', 5001)
  const db = getFirestore()
  connectFirestoreEmulator(db, 'localhost', 8080)
}



import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
  extend,
} from 'vee-validate';
import ja from 'vee-validate/dist/locale/ja';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
configure({
  bails: false,
});
import * as rules from 'vee-validate/dist/rules';
for (let rule in rules) {
  extend(rule, rules[rule]);
}
localize('ja', ja);

extend('emailReInput', {
  params: ['target'],
  validate(value, { target }) {
    //console.log("aaaaavaludate*"+value+" :"+target)
    return value === target;
  },
  message: "上のメールアドレスと同じものを入力して下さい"
});

extend('passwordReInput', {
  params: ['target'],
  validate(value, { target }) {
    //console.log("aaaaavaludate*"+value+" :"+target)
    return value === target;
  },
  message: "上のパスワードと同じものを入力して下さい"
});



Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)

import axios from 'axios' //追記
import VueAxios from 'vue-axios' //追記
Vue.use(VueAxios, axios) //追記

Vue.config.productionTip = false


router.beforeEach((to, from, next) => {
  //const mogeFlag = to.matched.some(record => record.meta.mogeFlag);
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth) {
    // このルートはログインされているかどうか認証が必要です。
    // もしされていないならば、ログインページにリダイレクトします。
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        const uid = user.uid
        //console.log("uid:"+uid)
        console.log("isEmulating:"+isEmulating)
        //if (isEmulating) {
          //const a = functions.httpsCallable('auth')
        //}else{
          const a = firebase.app().functions('asia-northeast1').httpsCallable('auth')
        //}
        a({uid: uid}).then(res=>{
          var user = res.data[0];
          store.commit('onAuthStateChanged', user);
          store.commit('onUserStatusChanged', user.uid ? true : false);

        }).catch(err => {
          console.error(err)
        })

        /*
        firebase.firestore().collection('users').doc(uid).get().then(async doc => {
          //this.user.id = doc.data().id
          user.name = doc.data().name;
          user.first_name = doc.data().first_name;
          user.last_name = doc.data().last_name;
          //user.level = doc.data().level;
          //user.office = doc.data().office;
          //user.email = doc.data().email;
          user = user ? user : {};
          //store.commit('onAuthStateChanged', user);
          //store.commit('onUserStatusChanged', user.uid ? true : false);
        })
        */
        next()
      } else {
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        })
      }
    })
  } else {
    next()
  }
})


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')


