<template>
  <div id="app">
    <b-navbar type="light" variant="light">
      <b-navbar-brand
        ><img src="./assets/logo_everymobile.svg"
      /></b-navbar-brand>
      <b-button
        variant="outline-secondary"
        size="sm"
        pill
        href="https://www.everymobile.jp/"
        >サービスサイトに戻る</b-button
      ></b-navbar
    >



    <b-container class="bv-example-row">

      <br>
      <b-card>
<b>404</b> みつかりません
      </b-card>
    </b-container>
  </div>
</template>

<script>
//import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  computed: {
    years() {
      const years = []
      for (let year = 1900; year <= new Date().getFullYear(); year++) {
        years.push(year)
      }
      return years
    },
    months() {
      const months = [...Array(12)].map((ele, i) => i + 1)
      return months
    },
    days() {
      let days = []
      if ((this.month === 2 && this.year % 4 === 0 && this.year % 100 !== 0) || (this.month === 2 && this.year % 400 === 0)) {
        days = [...Array(29)].map((ele, i) => i + 1)
      } else if (this.month === 2) {
        days = [...Array(28)].map((ele, i) => i + 1)
      } else if (this.month === 4 || this.month === 6 || this.month === 9 || this.month === 11) {
        days = [...Array(30)].map((ele, i) => i + 1)
      } else {
        days = [...Array(31)].map((ele, i) => i + 1)
      }
      return days
    },
  },

  data() {
    return {
      year: '',
    month: '',
    day: '',
        zipcode: '',
    address: '',
      gender: "",
      form: {
        email: "",
        name: "",
        food: null,
        checked: [],
      },
      foods: [
        { text: "Select One", value: null },
        "Carrots",
        "Beans",
        "Tomatoes",
        "Corn",
      ],
      show: true,
    };
  },

  methods: {
autoCompleteAddress(zipcode){
    this.axios.get(`https://api.zipaddress.net/?zipcode=${zipcode}`)
    .then((response)=>{
      this.address = response.data.data.fullAddress
      console.log("address:"+ JSON.stringify(response.data))
    })
    .catch(() => {
      this.address = ''
    })
  },

    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    greeting: function () {
      this.axios
        .get("/apis/hello")
        .then((res) => alert(res.data))
        .catch((e) => alert(e));
    },
  },
  /*
  components: {
    HelloWorld,
  },
  */
};
</script>

<style>
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
*/

</style>
