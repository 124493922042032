import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// ストアを作成
const store = new Vuex.Store({
  state: {
    count: 0,
    message:"",
    user: {},
    status: false
  },
  mutations: {
    // カウントアップするミューテーションを登録
    increment(state) {
      state.count++
    },
    onAuthStateChanged(state, user) {
      state.user = user; //firebaseが返したユーザー情報
    },
    onUserStatusChanged(state, status) {
      state.status = status; //ログインしてるかどうか true or false
    }
  },

  getters: {
    // 単純にステートを返す

    // リストの各要素の price プロパティの中から最大数値を返す
    max(state) {
      return state.list.reduce((a, b) => {
        return a > b.price ? a : b.price
      }, 0)
    },
    // 引数付きゲッター
    // listからidが一致する要素を返す
    item(state) {
      // 引数を使用するためアロー関数を返している
      return id => state.list.find(el => el.id === id)
    },
    // 別のゲッターを使うこともできる
    name(state, getters) {
      return id => getters.item(id).name
    },
    bu(state, getters) {
      return id => getters.item(id).bu
    },
    /*
    loginName(state) {
      // 引数を使用するためアロー関数を返している
      return message;
    },
    */
    user(state) {
      return state.user;
    },
    isSignedIn(state) {
      return state.status;
    }
  }



})
export default store



