<template>
  <div id="app">
    <b-navbar type="light" variant="light">
      <b-navbar-brand
        ><img src="./assets/logo_everymobile.svg"
      /></b-navbar-brand>
</b-navbar
    >

    
    <b-container class="bv-example-row" v-if="!onProgress()">
    <br><br>
      <h5>エラー</h5>
      お手数ですが、はじめからやり直してください。
    </b-container> 



    <b-container class="bv-example-row" v-if="onProgress()">
      <div id="1" v-if="first">
        <br />
        <h4 class="text-center">
          <strong>プランをご選択ください</strong>
        </h4>
        <br />
        <b-card header="プラン選択">
          <b-card bg-variant="light">
            <b-row>
              <b-col cols="4" sm="4">
                <div align="center">
                  <img alt="Vue logo" src="./assets/img1_md.png" width="100" />
                </div>
              </b-col>
              <b-col cols="8" sm="8">
                <div>
                データ容量 5G<br />
                国内いつでもかけ放題<br />
                </div>
              </b-col>
            </b-row>
          </b-card>

          <b-table-simple>
            <b-tr>
              <b-th class="text-right">プラン選択</b-th>
              <b-td>
                <b-form inline>
                  <div>
                    <b-form-radio-group
                      button-variant="primary"
                      size="lg"
                      name="radio-btn-outline"
                    >
                      <b-form-radio
                        v-model="plan"
                        name="radio-size"
                        size="lg"
                        value="d"
                      >
                        <b-card title="Dプラン">
                          <b-card-text>ドコモの通信エリアに対応</b-card-text>
                        </b-card>
                      </b-form-radio>
                      <b-form-radio
                        v-model="plan"
                        name="radio-size"
                        size="lg"
                        value="a"
                      >
                        <b-card title="Aプラン">
                          <b-card-text>auの通信エリアに対応</b-card-text>
                        </b-card>
                      </b-form-radio>
                    </b-form-radio-group>
                  </div>
                </b-form>
              </b-td>
            </b-tr>
          </b-table-simple>
        </b-card>
        <br />

        <div align="center">
          <b-button
            pill
            size="lg"
            variant="outline-success"
            v-if="plan"
            @click="goSecond()"
            >次に進む</b-button
          >
        </div>

        <!--
          <b-row align-h="center" cols="2" class="justify-content-md-center">
            <b-col sm="4">
<div align="center">
              <b>既にご利用の方</b><br>
              <b-button pill block size="lg" variant="outline-success" v-if="plan">マイページログイン</b-button>
              </div>

            </b-col>
            <b-col sm="4">

<div align="center">
              <b>新規でお申し込みの方</b><br>
              <b-button pill block size="lg" variant="outline-success" v-if="plan" @click="goSecond()">新規登録</b-button>
</div>
            </b-col>
          </b-row>
-->
      </div>

      <div id="1selected" v-if="firstSelected">
        <br />
        <h4 class="text-center">
          <strong>申込内容の確認</strong>
        </h4>
        <br />
        <b-card bg-variant="light">
          <b-row>
            <b-col cols="4" sm="4">
              <div align="center">
                <img alt="Vue logo" src="./assets/img1_md.png" width="100" />
              </div>
            </b-col>
            <b-col cols="8" sm="8">
              <div>
                データ容量 5G<br />
                国内いつでもかけ放題<br />
              </div>
            </b-col>
          </b-row>
        </b-card>
        <div v-if="plan == 'a'">
          <b-card title="Aプラン">
            <b-card-text>auの通信エリアに対応</b-card-text>
            <p>月額基本料金 <b>4,950円</b></p>
            <div align="right">
              <b-button
                pill
                size="sm"
                variant="outline-secondary"
                @click="backFirst()"
                >プランを変更する</b-button
              >
            </div>
          </b-card>
        </div>
        <div v-if="plan == 'd'">
          <b-card title="Dプラン">
            <b-card-text>ドコモの通信エリアに対応</b-card-text>
            <p>月額基本料金 <b>4,950円</b></p>
            <div align="right">
              <b-button
                pill
                size="sm"
                variant="outline-secondary"
                @click="backFirst()"
                >プランを変更する</b-button
              >
            </div>
          </b-card>
        </div>
        <br>
      <div>



<!--
    <p><b>{{user.last_name}} {{user.first_name}}</b> 様</p>
    <p class="mb-1">
      〒{{user.zipcode}} {{user.address1}}{{user.address2}}{{user.address3}}
    </p>
    -->

    



    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">事務手数料</h5>
      </div>

    <p class="mb-1">
      3,300円
    </p>
    <br>



<b-button pill size="lg" block variant="outline-success" @click="applicationCompleted()">この内容で申し込む</b-button>






          <br>

      </div>


      </div>

    </b-container>
    <br />
    <footer class="border-top">

      <br />
      <p align="center">&copy; everymobile inc.</p>
    </footer>
  </div>
</template>

<script>
import firebase from 'firebase/compat';
//import HelloWorld from "./components/HelloWorld.vue";
import { extend } from "vee-validate";
import { required, numeric, regex, confirmed } from "vee-validate/dist/rules";

extend("required", required);
extend("numeric", numeric);
extend("regex", regex);
extend("confirmed", confirmed);


export default {
  name: "App",
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },

  data() {
    return {
      //id_verified:null,
      order_completed:false,
      zero: true,
      first: false,
      firstSelected: true,
      plan: "",
      second: false,
      isChecked: true,

      last_name: "",
      first_name: "",
      last_name_kana: "",
      first_name_kana: "",
      gender: "",

      year: "",
      month: "",
      day: "",
      zipcode: "",
      address1: "",
      address2: "",
      address3: "",
      email_a: "",
      email_b: "",
      passwd_a: "",
      passwd_b: "",
      //users: firebase.firestore().collection("users"),

      form: {
        email: "",
        name: "",
        food: null,
        checked: [],
      },
      foods: [
        { text: "Select One", value: null },
        "Carrots",
        "Beans",
        "Tomatoes",
        "Corn",
      ],
      show: true
    };
  },
  mounted() {
  
    if (localStorage.plan) {
      this.plan = localStorage.plan;
      console.log("plan:"+this.plan)
    }
  },
  watch: {
    plan(newPlan) {
      console.log("plan:"+newPlan)
      localStorage.plan = newPlan;
    }
  },

  methods: {
    onProgress(){

if(localStorage.onProgress){
  return true
}else{
  return false
}
    },
  applicationCompleted(){

var order_data = {};
order_data.plan = this.plan;

  var me = this;
      const auth = firebase.app().functions('asia-northeast1').httpsCallable('application_completed')
      auth(order_data).then(function(result) {
        console.log(result.data)
    
        localStorage.clear()
        firebase.auth().signOut().then(()=>{
          console.log("ログアウトしました");
          me.$router.push("/completed");
          })

      }).catch(err => {
        console.error(err)
      })

  },
  async redirectId(){
    console.log("mounted")
    //var  u = await this.$store.getters.user;
    if(this.id_verified === null || this.id_verified === undefined ){
      console.log("IDDDD")
      this.$router.push("/id");
    }else{
      console.log("are:"+this.id_verified)
    }
  },
    onClick: function () {
      this.isChecked = !this.isChecked;
    },
    goSignUp: function () {
            this.$router.push("/signup");
            return
    },
    goLogin: function () {
            this.$router.push("/login");
            return
    },
    goId: function () {
            this.$router.push("/id");
            return
    },


    toHalfWidth(input) {
      return input.replace(/[！-～]/g, function (input) {
        return String.fromCharCode(input.charCodeAt(0) - 0xfee0);
      });
    },
    goPlan() {
      this.zero = false;
      this.first = true;
    },
    goSecond() {
      console.log("second!!!");
      this.first = false;
      this.firstSelected = this.second = !this.first;
    },

    backFirst() {
      console.log("second!!!");
      this.first = true;
      this.firstSelected = this.second = !this.first;
    },



    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    greeting: function () {
      this.axios
        .get("/apis/hello")
        .then((res) => alert(res.data))
        .catch((e) => alert(e));
    },

    changeErrorString(code) {
      switch (code) {
        case "auth/cancelled-popup-request":
        case "auth/popup-closed-by-user":
          return null;
        case "auth/email-already-in-use":
          return "このメールアドレスは使用されています";
        case "auth/invalid-email":
          return "メールアドレスの形式が正しくありません";
        case "auth/user-disabled":
          return "サービスの利用が停止されています";
        case "auth/user-not-found":
          return "メールアドレスまたはパスワードが違います";
        case "auth/user-mismatch":
          return "メールアドレスまたはパスワードが違います";
        case "auth/weak-password":
          return "パスワードは6文字以上にしてください";
        case "auth/wrong-password":
          return "メールアドレスまたはパスワードが違います";
        case "auth/popup-blocked":
          return "認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください";
        case "auth/operation-not-supported-in-this-environment":
        case "auth/auth-domain-config-required":
        case "auth/operation-not-allowed":
        case "auth/unauthorized-domain":
          return "現在この認証方法はご利用頂けません";
        case "auth/requires-recent-login":
          return "認証の有効期限が切れています";
        default:
          return "エラーが発生しました。しばらく時間をおいてお試しください";
      }
    },



  },
  /*
  components: {
    HelloWorld,
  },
  */
};
</script>

<style>
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
*/

</style>
