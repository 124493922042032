import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/MyPage.vue'; 
import Test from '@/Test.vue';
import Id from '@/Id.vue';
import NotFound from '@/NotFound.vue'; 
import Login from '@/Login.vue'; 
import Select from '@/Select.vue'; 
import SignUp from '@/SignUp.vue';
import Order from '@/Order.vue';
import Completed from '@/Completed.vue'; 
//import About from '/views/About.vue'

Vue.use(Router) // プラグイン（どこでも使える機能）を適用するために記載

export default new Router({
    mode: 'history',
    routes:[
        {
            path:"/",           // URL 
            component: Home,   // 上記URLのときに表示するコンポーネント
            meta: { 
                requiresAuth: true,
                title: 'エブリモバイル', 
                desc: 'エブリモバイル'
            } 
        }
        ,
        {
            path:"/select",           // URL 
            component: Select,   // 上記URLのときに表示するコンポーネント
            meta: { title: 'エブリモバイルのお申し込み', desc: 'エブリモバイルお申し込みページ' }
        },
        {
            path:"/signup",           // URL 
            component: SignUp,   // 上記URLのときに表示するコンポーネント
            meta: { title: 'エブリモバイル', desc: 'エブリモバイル' }
        },

        {
            path: '/test',
            component:Test,
            meta: { 
                requiresAuth: true
               } 
        },
        {
            path: '/id',
            component:Id,
            meta: { 
                requiresAuth: true
               } 
        },
        {
            path: '/order',
            component:Order,
            meta: { 
                requiresAuth: true
               } 
        },
        {
            path: '/completed',
            component:Completed,
            meta: { title: 'エブリモバイル', desc: 'エブリモバイル' }
        },

        {
            path: '/login',
            component:Login,
            meta: { title: 'エブリモバイル', desc: 'エブリモバイル' }  
        },
        {
            path: '/login/:qid',
            component:Login,
            meta: { title: 'エブリモバイル', desc: 'エブリモバイル' }   
        },
        
        { 
            path: '/*', 
            meta: { title: 'エブリモバイル', desc: 'エブリモバイル'},
            component: NotFound
        }

        
    ]
})

