<template>
  <div id="app">

        <b-navbar type="light" variant="light">
      <b-navbar-brand
        ><img src="./assets/logo_everymobile.svg"
      /></b-navbar-brand>
      <b-button
        variant="outline-secondary"
        size="sm"
        pill
        href="https://www.everymobile.jp/"
        >サービスサイトに戻る</b-button
      ></b-navbar
    >

    <b-container class="bv-example-row">
<br>

<div align="center">
      <b-card
        border-variant="light"
        header-border-variant="light"
        align="left"
        style="max-width: 40rem;"
      >

<h4><b>ログイン</b></h4>
<br>
  <form>




      <label><b>everyID(メールアドレス)</b></label>
       <b-form-input type="email" v-model="email" size="lg" placeholder="登録したメールアドレスを入力してください"></b-form-input>
      <br>
      <label><b>パスワード</b></label>
      <b-form-input type="password" v-model="password" size="lg" placeholder="パスワードを入力してください"></b-form-input>

<br>
      <b-alert show variant="danger" v-show="errorAlert">{{errorAlert}}</b-alert>
          <b-button
            pill
            size="lg"
            variant="outline-success"
            @click="login()"
            >ログイン</b-button
          >
  </form>


      </b-card>
</div>




      <br>
      <!--
      <b-card header="基本情報の入力">
<b-button @click="greeting()">aaa</b-button>
<b-button @click="login()">login</b-button>
<b-button @click="logout()">logout</b-button>
<b-button @click="authTest()">authTest</b-button>
      </b-card>
      -->





    </b-container>

    <br />
    <footer class="border-top">

      <br />
      <p align="center">&copy; everymobile inc.</p>
    </footer>


  </div>
</template>

<script>
//import HelloWorld from "./components/HelloWorld.vue";
//import firebase from "firebase";
import firebase from 'firebase/compat';
//import "firebase/firestore";


export default {
  name: "App",
  computed: {
  
  },

  data() {
    return {
      email:'',
      password:'',
      errorAlert:'',
      qid:''
    };
  },
  created(){
  //const qid = this.$route.params.qid ? this.$route.params.qid : '';
  },

  methods: {

changeEString(code) {
  //var code = "code"


      switch (code) {
        case "auth/cancelled-popup-request":
        case "auth/popup-closed-by-user":
          return null;
        case "auth/email-already-in-use":
          return "このメールアドレスは使用されています";
        case "auth/invalid-email":
          return "メールアドレスの形式が正しくありません";
        case "auth/user-disabled":
          return "サービスの利用が停止されています";
        case "auth/user-not-found":
          return "メールアドレスまたはパスワードが違います";
        case "auth/user-mismatch":
          return "メールアドレスまたはパスワードが違います";
        case "auth/weak-password":
          return "パスワードは6文字以上にしてください";
        case "auth/wrong-password":
          return "メールアドレスまたはパスワードが違います";
        case "auth/popup-blocked":
          return "認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください";
        case "auth/operation-not-supported-in-this-environment":
        case "auth/auth-domain-config-required":
        case "auth/operation-not-allowed":
        case "auth/unauthorized-domain":
          return "現在この認証方法はご利用頂けません";
        case "auth/requires-recent-login":
          return "認証の有効期限が切れています";
        default:
          return "エラーが発生しました。しばらく時間をおいてお試しください";
      }

    },

authTest(){
      const auth = firebase.functions().httpsCallable('authTest')
      auth({text: 'test'}).then(function(result) {
        console.log(result.data.ok)
      }).catch(err => {
        console.error(err)
      })
    },
  


login() {
  this.qid = this.$route.params.qid ? this.$route.params.qid : '';
  this.errorAlert = "";
  var me = this;
      firebase.auth().signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.$router.push("/"+ me.qid);
        })
        .catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("errcode:"+errorCode)
      console.log("errMsg:"+errorMessage)
      var aaa  = me.changeEString(errorCode)
             me.errorAlert = aaa;//this.changeEString("auth/weak-password")""
      })
    },
    logout() {
      firebase.auth().signOut().then(()=>{
    console.log("ログアウトしました");
  })
    },
    callAPI() {
      this.$axios.get('https://{ESPv2イメージでデプロイしたCloud Runホスト名}/', {
        headers: {
          Authorization: `Bearer ${this.idToken}`,
        }
      }).then(res => {
        this.message = JSON.stringify(res.data)
      }).catch(error => {
        this.message = JSON.stringify(error.response.data)
      })
    },
    
    greeting: function () {
      this.axios
        .get("/apis/hello")
        .then((res) => alert(res.data))
        .catch((e) => alert(e));
    },
  },
  /*
  components: {
    HelloWorld,
  },
  */
};
</script>

<style>
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
*/

</style>
