var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('b-navbar',{attrs:{"type":"light","variant":"light"}},[_c('b-navbar-brand',[_c('img',{attrs:{"src":require("./assets/logo_everymobile.svg")}})])],1),_c('b-container',{staticClass:"bv-example-row"},[_c('div',{attrs:{"id":"2"}},[_c('br'),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(passes){return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return passes(_vm.onSubmit)}}},[_c('b-card',{attrs:{"header":"基本情報の入力"}},[_c('b-row',[_c('b-col',[_c('b-table-simple',[_c('b-tr',[_c('b-th',{staticClass:"text-right"},[_vm._v("ご契約者名(漢字)")]),_c('b-td',[_c('b-form',{attrs:{"inline":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6","sm":"6"}},[_c('label',{staticClass:"sr-only",attrs:{"for":"inline-form-input-name"}},[_vm._v("姓")]),_c('ValidationProvider',{attrs:{"name":"姓","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('b-form-input',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"state":validated ? valid : null,"id":"inline-form-input-name","aria-label":"Last name","placeholder":"絵振"},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v("必須")]),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',{staticClass:"sr-only",attrs:{"for":"inline-form-input-name"}},[_vm._v("名")]),_c('ValidationProvider',{attrs:{"name":"名","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('b-form-input',{attrs:{"state":validated ? valid : null,"id":"inline-form-input-username","placeholder":"太郎"},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v("必須")]),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('b-tr',[_c('b-th',{staticClass:"text-right"},[_vm._v("ご契約者名(カナ)")]),_c('b-td',[_c('b-form',{attrs:{"inline":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6","sm":"6"}},[_c('label',{staticClass:"sr-only",attrs:{"for":"inline-form-input-name"}},[_vm._v("姓(カナ)")]),_c('ValidationProvider',{attrs:{"name":"姓(カナ)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('b-form-input',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"state":validated ? valid : null,"id":"inline-form-input-name","placeholder":"エブリ"},model:{value:(_vm.last_name_kana),callback:function ($$v) {_vm.last_name_kana=$$v},expression:"last_name_kana"}}),_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v("必須")]),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('label',{staticClass:"sr-only",attrs:{"for":"inline-form-input-name"}},[_vm._v("名(カナ)")]),_c('ValidationProvider',{attrs:{"name":"名(カナ)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('b-form-input',{attrs:{"state":validated ? valid : null,"id":"inline-form-input-name","placeholder":"タロウ"},model:{value:(_vm.first_name_kana),callback:function ($$v) {_vm.first_name_kana=$$v},expression:"first_name_kana"}}),_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v("必須")]),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('b-tr',[_c('b-th',{staticClass:"text-right"},[_vm._v("性別")]),_c('b-td',[_c('b-form',{attrs:{"inline":""}},[_c('div',[_c('ValidationProvider',{attrs:{"name":"性別","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('b-form-radio-group',{attrs:{"button-variant":"outline-primary","size":"lg","name":"radio-btn-outline","state":validated ? valid : null,"buttons":""}},[_c('b-form-radio',{attrs:{"name":"radio-size","size":"lg","value":"0"},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_vm._v(" 男性")]),_c('b-form-radio',{attrs:{"name":"radio-size","size":"lg","value":"1"},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_vm._v(" 女性")])],1),_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v("必須")]),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])],1)],1),_c('b-tr',[_c('b-th',{staticClass:"text-right"},[_vm._v("生年月日")]),_c('b-td',[_c('b-row',[_c('b-col',{attrs:{"sm":"3","xs":"8"}},[_c('ValidationProvider',{attrs:{"name":"年","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('b-form-select',{attrs:{"options":_vm.years,"state":validated ? valid : null},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}}),_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v("必須")]),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"1","xs":"4"}},[_vm._v("年")]),_c('b-col',{attrs:{"sm":"3","xs":"8"}},[_c('ValidationProvider',{attrs:{"name":"月","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('b-form-select',{attrs:{"options":_vm.months,"state":validated ? valid : null},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"1","xs":"4"}},[_vm._v("月")]),_c('b-col',{attrs:{"sm":"3","xs":"8"}},[_c('ValidationProvider',{attrs:{"name":"日","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('b-form-select',{attrs:{"options":_vm.days,"state":validated ? valid : null},model:{value:(_vm.day),callback:function ($$v) {_vm.day=$$v},expression:"day"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"1","xs":"4"}},[_vm._v("日")])],1)],1)],1),_c('b-tr',[_c('b-th',{staticClass:"text-right"},[_vm._v("住所")]),_c('b-td',[_c('label',{attrs:{"for":""}},[_vm._v("郵便番号:")]),_c('ValidationProvider',{attrs:{"name":"zipcode","rules":"required|postalcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('b-input-group',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"prepend":"〒"}},[_c('b-form-input',{attrs:{"type":"text","prepend":"〒","placeholder":"000-0000","state":validated ? valid : null},on:{"blur":function($event){return _vm.autoCompleteAddress(_vm.zipcode)}},model:{value:(_vm.zipcode),callback:function ($$v) {_vm.zipcode=$$v},expression:"zipcode"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.autoCompleteAddress(_vm.zipcode)}}},[_vm._v("住所自動入力")])],1)],1),_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v("必須")]),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('br'),_c('label',{attrs:{"for":"address1"}},[_vm._v("都道府県/市区町村:")]),_c('b-form-input',{attrs:{"readonly":"","type":"text","id":"address1"},model:{value:(_vm.address1),callback:function ($$v) {_vm.address1=$$v},expression:"address1"}}),_c('br'),_c('label',{attrs:{"for":"address2"}},[_vm._v("丁目番地:")]),_c('ValidationProvider',{attrs:{"name":"丁目番地ß","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"例) 1-1-1","state":validated ? valid : null},model:{value:(_vm.address2),callback:function ($$v) {_vm.address2=$$v},expression:"address2"}}),_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v("必須")]),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('br'),_c('label',{attrs:{"for":"address3"}},[_vm._v("マンション名/部屋番号:")]),_c('b-form-input',{attrs:{"type":"text","placeholder":"例) 〇〇マンション101号室"},model:{value:(_vm.address3),callback:function ($$v) {_vm.address3=$$v},expression:"address3"}}),_c('br')],1)],1),_c('b-tr',[_c('b-th',{staticClass:"text-right"},[_vm._v("メールアドレス")]),_c('b-td',[_c('ValidationProvider',{attrs:{"name":"mailaddress","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('b-form-input',{attrs:{"type":"text","state":validated ? valid : null,"placeholder":"例) every@everymobile.jp"},model:{value:(_vm.email_a),callback:function ($$v) {_vm.email_a=$$v},expression:"email_a"}}),_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v("必須")]),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('br'),_c('label',{attrs:{"for":"email_a"}},[_vm._v("確認のため再度入力してください")]),_c('ValidationProvider',{attrs:{"name":"reMailaddress","rules":"required|emailReInput:@mailaddress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"例) every@everymobile.jp","state":validated ? valid : null},model:{value:(_vm.email_b),callback:function ($$v) {_vm.email_b=$$v},expression:"email_b"}}),_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v("必須")]),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-tr',[_c('b-th',{staticClass:"text-right"},[_vm._v("パスワード(6文字以上)")]),_c('b-td',[_c('p',[_vm._v("マイページのご利用に必要なパスワードです")]),_c('ValidationProvider',{attrs:{"name":"password_a","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"password","type":_vm.inputType,"aria-describedby":"password-help password-feedback","placeholder":"パスワードを入力","state":validated ? valid : null,"trim":""},model:{value:(_vm.passwd_a),callback:function ($$v) {_vm.passwd_a=$$v},expression:"passwd_a"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":_vm.onClick}},[_c('span',[_c('b-icon',{attrs:{"icon":_vm.iconType}})],1)])],1)],1),_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v("必須")]),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('br'),_c('label',{attrs:{"for":"passwd_b"}},[_vm._v("確認のため再度入力してください")]),_c('ValidationProvider',{attrs:{"name":"confirm","rules":"required|passwordReInput:@password_a"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"passwd_b","type":_vm.inputType,"aria-describedby":"password-help password-feedback","placeholder":"パスワードを入力","state":validated ? valid : null,"trim":""},model:{value:(_vm.passwd_b),callback:function ($$v) {_vm.passwd_b=$$v},expression:"passwd_b"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":_vm.onClick}},[_c('span',[_c('b-icon',{attrs:{"icon":_vm.iconType}})],1)])],1)],1),_c('b-form-text',{attrs:{"text-variant":"danger"}},[_vm._v("必須")]),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('br'),(passes.invalid || !passes.validated || !_vm.address1)?_c('div',{attrs:{"align":"center"}},[_c('b-button',{attrs:{"variant":"outline-secondary","pill":"","size":"lg","disabled":passes.invalid || !passes.validated || !_vm.address1}},[_vm._v("フォームを全て入力してください")])],1):_c('div',{attrs:{"align":"center"}},[_c('b-button',{attrs:{"pill":"","size":"lg","variant":"outline-success"},on:{"click":_vm.signUp}},[_vm._v("登録する")])],1)],1)]}}])})],1)]),_c('br'),_vm._m(0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"border-top"},[_c('br'),_c('p',{attrs:{"align":"center"}},[_vm._v("© everymobile inc.")])])}]

export { render, staticRenderFns }